import React, { useEffect, useState, useRef } from "react";
import instaEmbeds from "../data/instaEmbeds.json";
import "./MeetRecaps.css";

const MeetRecaps = ({ headerRef }) => {
  const [embeds] = useState(instaEmbeds);
  const [filteredEmbeds, setFilteredEmbeds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const embedsPerPage = 4;
  const containerRef = useRef(null);

  useEffect(() => {
    loadInstagramEmbedsScript();
  }, []);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [currentPage, filteredEmbeds]);

  const loadInstagramEmbedsScript = () => {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = "https://www.instagram.com/embed.js";
    document.body.appendChild(script);

    script.onload = () => {
      window.instgrm.Embeds.process();
    };

    return () => {
      document.body.removeChild(script);
    };
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = embeds.filter((embed) =>
      embed.name.toLowerCase().includes(searchTerm)
    );
    setFilteredEmbeds(filtered);
    setCurrentPage(1);
  };

  const indexOfLastEmbed = currentPage * embedsPerPage;
  const indexOfFirstEmbed = indexOfLastEmbed - embedsPerPage;
  const currentEmbeds = filteredEmbeds.length
    ? filteredEmbeds.slice(indexOfFirstEmbed, indexOfLastEmbed)
    : embeds.slice(indexOfFirstEmbed, indexOfLastEmbed);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (headerRef && headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const totalPages = Math.ceil(
    (filteredEmbeds.length ? filteredEmbeds.length : embeds.length) /
      embedsPerPage
  );

  return (
    <div className="meet-recaps">
      <input
        type="text"
        placeholder="Search by name"
        onChange={handleSearch}
        className="search-bar"
      />
      <div className="pagination top">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <div className="posts-container">
        {currentEmbeds.map((embed, index) => (
          <div key={index} className="instagram-post" ref={containerRef}>
            <div className="post-container">
              <div dangerouslySetInnerHTML={{ __html: embed.embedCode }}></div>
            </div>
            <div className="caption-container">{embed.caption}</div>
          </div>
        ))}
      </div>
      <div className="pagination bottom">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MeetRecaps;
