// src/components/Homepage.js
import React from "react";
import Gallery from "./Gallery";
import TestimonialsCarousel from "./TestimonialsCarousel";
import ServicesCards from "./ServicesCards";
import FederationLogos from "./FederationLogos";
import "./Homepage.css";

const Homepage = ({ servicesRef }) => (
  <div>
    <div ref={servicesRef} id="services-section">
      <ServicesCards />
    </div>
    <Gallery />
    <TestimonialsCarousel />
    <FederationLogos />
  </div>
);

export default Homepage;
