// src/components/ServicesCards.js
import React from "react";
import "./ServicesCards.css";

const services = [
  {
    title: "Communication",
    description: (
      <>
        <ul>
          <li>
            Daily communication system with comprehensive discussion of
            programming, technique analysis, and any relevant notes
          </li>
          <li>Guaranteed 24-hour response time via WhatsApp</li>
        </ul>
      </>
    ),
  },
  {
    title: "Programming",
    description: (
      <>
        <ul>
          <li>
            Fully individualized, modern powerlifting programming with recovery
            variable tracking, progress tracking, and scheduling
          </li>
          <li>Programming shared via Google Sheets</li>
        </ul>
      </>
    ),
  },
  {
    title: "Meet-Day Handling",
    description: (
      <>
        <ul>
          <li>
            Professional meet-day handling at local and national competitions
            included
          </li>
          <li>Additional fees for non-local or national competitions</li>
        </ul>
      </>
    ),
  },
];

const ServicesCards = () => (
  <div className="services-cards">
    {services.map((service, index) => (
      <div key={index} className="card">
        <div className="card-content">
          <h3>{service.title}</h3>
          {service.description}
        </div>
      </div>
    ))}
  </div>
);

export default ServicesCards;
