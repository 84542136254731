import React, { useState, useEffect } from "react";
import "./Header.css";

// Dynamically import all images from the homepage-header folder
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context(
    "../assets/images/homepage-header",
    false,
    /\.(jpe?g|png|svg)$/
  )
);

const getRandomImage = (arr) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

const Header = ({ servicesRef }) => {
  const [headerImage, setHeaderImage] = useState(null);

  useEffect(() => {
    const randomImage = getRandomImage(images);
    setHeaderImage(randomImage);
  }, []);

  const handleScroll = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleServicesClick = (event) => {
    event.preventDefault();
    handleScroll(servicesRef);
  };

  return (
    <div
      className="header-container"
      style={{
        backgroundImage: headerImage ? `url(${headerImage})` : "none",
      }}
    >
      <div className="header-content">
        <h1 className="header-title">
          Discover Your Strength with SSS Powerlifting
        </h1>
        <p className="header-subtitle">
          Expert coaching and programming to help you achieve your goals
        </p>
        <div className="header-buttons">
          <a href="/about" className="header-button">
            About
          </a>
          <a
            href="/services"
            className="header-button"
            onClick={handleServicesClick}
          >
            Services
          </a>
          <a href="/contact" className="header-button">
            Contact
          </a>
        </div>
        <h2 className="header-service-text">
          SSS Powerlifting Coaching Service
        </h2>
      </div>
    </div>
  );
};

export default Header;
