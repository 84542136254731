// src/components/FederationLogos.js
import React from "react";
import usaplLogo from "../assets/images/logos/usapl.png";
import wrpfLogo from "../assets/images/logos/wrpf.png";
import napfLogo from "../assets/images/logos/napf.png";
import ipfLogo from "../assets/images/logos/ipf.png";
import "./FederationLogos.css";

const FederationLogos = () => {
  return (
    <div className="federation-logos-section">
      <h2>Federations</h2>
      <div className="logos-container">
        <div className="logo-card">
          <img src={usaplLogo} alt="USAPL" className="federation-logo" />
        </div>
        <div className="logo-card">
          <img src={wrpfLogo} alt="WRPF" className="federation-logo" />
        </div>
        <div className="logo-card">
          <img src={napfLogo} alt="NAPF" className="federation-logo" />
        </div>
        <div className="logo-card">
          <img src={ipfLogo} alt="IPF" className="federation-logo" />
        </div>
      </div>
    </div>
  );
};

export default FederationLogos;
