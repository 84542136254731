// src/components/Footer.js
import React from "react";
import "./Footer.css";
import footerImage from "../assets/images/footer-image.jpg"; // Ensure this path is correct
import { FaInstagram, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-image-container">
        <img
          src={footerImage}
          alt="Footer Background"
          className="footer-image"
        />
      </div>
      <div className="footer-content">
        <div className="footer-icons">
          <a
            href="https://www.instagram.com/ssspowerlifting?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            className="footer-icon"
          >
            <FaInstagram size={24} />
          </a>
          <a href="mailto:ssspowerlifting@gmail.com" className="footer-icon">
            <FaEnvelope size={24} />
          </a>
        </div>
        <p>© 2024 Saillant Strength Systems Powerlifting</p>
        <p>Website by Michael Lebreck</p>
      </div>
    </div>
  );
};

export default Footer;
