// src/components/TestimonialsCarousel.js
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./TestimonialsCarousel.css";
import testimonials from "../data/testimonials.json";

const TestimonialsCarousel = () => (
  <div className="carousel-container">
    <div className="testimonials-carousel">
      <Carousel
        showThumbs={false}
        autoPlay
        interval={5000}
        infiniteLoop
        stopOnHover
      >
        {testimonials.map((testimonial, index) => (
          <div key={index} className="carousel-slide">
            <p>{testimonial.text}</p>
            <p>
              <strong>- {testimonial.author}</strong>
            </p>
          </div>
        ))}
      </Carousel>
    </div>
  </div>
);

export default TestimonialsCarousel;
