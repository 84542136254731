// src/components/FullGallery.js
import React from "react";
import "./FullGallery.css";
import headerImage from "../assets/images/gallery-header.jpg"; // Import your header image

// Dynamically import all images from the gallery folder
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("../assets/images/gallery", false, /\.(png|jpe?g|svg)$/)
);

const FullGallery = () => (
  <div className="full-gallery">
    <div className="header-image-container">
      <div className="header-overlay"></div>
      <img src={headerImage} alt="Header" className="header-image" />
    </div>
    <div className="gallery-content">
      <h2>Moments and Memories</h2>
      <div className="full-gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="full-gallery-item">
            <img src={image} alt="" className="responsive-image" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default FullGallery;
