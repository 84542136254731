// src/components/Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../assets/images/ssspowerlifting_logo.png";

const Navbar = ({ servicesRef, contactRef }) => {
  const handleScroll = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <nav className="navbar">
      <ul>
        <li className="navbar-logo">
          <Link to="/">
            <img src={logo} alt="SSS Powerlifting Logo" />
          </Link>
        </li>
        <li>
          <Link to="/" onClick={() => handleScroll(servicesRef)}>
            Services
          </Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/full-gallery">Gallery</Link>
        </li>
        <li>
          <Link to="/contact">Apply/Contact</Link>
        </li>
        <li>
          <Link to="/meet-recaps">Meet Recaps</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
