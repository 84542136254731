import React, { useState, useEffect } from "react";
import "./Gallery.css";

// Dynamically import all images from the gallery folder
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("../assets/images/gallery", false, /\.(png|jpe?g|svg)$/)
);

const getRandomImages = (arr, num) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

const Gallery = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [numImages, setNumImages] = useState(3);

  const updateNumImages = () => {
    const width = window.innerWidth;
    if (width < 600) {
      setNumImages(1);
    } else if (width < 1024) {
      setNumImages(2);
    } else {
      setNumImages(3);
    }
  };

  useEffect(() => {
    updateNumImages(); // Set initial number of images
    const handleResize = () => {
      updateNumImages();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const randomImages = getRandomImages(images, numImages);
    setSelectedImages(randomImages);
  }, [numImages]);

  return (
    <div className="gallery">
      {selectedImages.map((image, index) => (
        <div key={index} className="gallery-item">
          <img src={image} alt="" className="responsive-image" />
        </div>
      ))}
    </div>
  );
};

export default Gallery;
