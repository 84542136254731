import React from "react";
import "./Contact.css";
import { FaInstagram, FaEnvelope } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="form-container">
        <iframe
          src="https://forms.gle/3bMmMLyucKqXweKB8"
          width="100%"
          height="600"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Coaching Application Form"
        >
          Loading…
        </iframe>
      </div>
      <div className="contact-message">
        <p>
          Got questions or want to reach out?{" "}
          <a
            href="https://forms.gle/Ahsd2vAPGicExDSH8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
        </p>
      </div>
      <div className="contact-icons">
        <a
          href="https://www.instagram.com/ssspowerlifting?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
          rel="noopener noreferrer"
          className="contact-icon"
        >
          <FaInstagram size={30} />
        </a>
        <a href="mailto:ssspowerlifting@gmail.com" className="contact-icon">
          <FaEnvelope size={30} />
        </a>
      </div>
    </div>
  );
};

export default Contact;
