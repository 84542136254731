// src/components/About.js
import React, { useState, useEffect, useRef } from "react";
import "./About.css";
import portrait from "../assets/images/portrait.jpg";

const About = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const textRef = useRef(null);
  const imageRef = useRef(null);

  const adjustTextHeight = () => {
    if (imageRef.current && textRef.current) {
      textRef.current.style.maxHeight = `${imageRef.current.clientHeight}px`;
    }
  };

  useEffect(() => {
    if (imageLoaded) {
      adjustTextHeight();
    }

    window.addEventListener("resize", adjustTextHeight);
    return () => {
      window.removeEventListener("resize", adjustTextHeight);
    };
  }, [imageLoaded]);

  return (
    <div className="about-section">
      <div className="about-image-container" ref={imageRef}>
        <img
          src={portrait}
          alt="Coach Andrew Saillant"
          className="about-image"
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <div className="about-text" ref={textRef}>
        <h2>About Me</h2>
        <p>
          My name is Andrew Saillant, owner and coach of Saillant Strength
          Systems Powerlifting, established in 2022. I am 23 years old, and from
          Rhode Island.
        </p>
        <p>
          I have been lifting weights since 2017, and powerlifting specifically
          since 2019 when I did my first competition, and am still an active
          competitor and referee in the USAPL to this day. I began my coaching
          journey in earnest during my freshman year of college in 2019 when I
          decided to enroll in an extracurricular course that would prepare me
          to sit for the American Council on Exercise Personal Training
          Certificate exam. After passing that exam, I was hired by my school’s
          campus recreation department as an in-person personal trainer, where I
          quickly became passionate for coaching, and even changed my entire
          career path in school from physics to physical therapy. When the COVID
          pandemic hit, I shifted into online coaching, offering up free
          programming services for anyone with any goals, which ended up being
          50+ different people from all over, which opened my eyes to the
          potential of being an online coach. Over the next three years in
          school, I worked with dozens of clients in person of all different
          backgrounds and demographics, while simultaneously getting very deep
          into powerlifting. I hired my own personal online coach, and also
          joined a local powerlifting gym called Cambridge Strength and
          Conditioning at first just for the equipment, but quickly realized I
          wanted to stay for the community. Soon, I was approached by a handful
          of students from my school who knew I was into powerlifting and also a
          trainer, who were interested in my help as a coach. I started
          programming for them, and things began to snowball through word of
          mouth, and I started my business unofficially a few months later. The
          founding of the Boston College Barbell Club and the first annual mock
          meet was also pivotal in my early success, and by the end of my senior
          year of college, I had a small, but fantastic roster of young and
          eager powerlifters. When I graduated, I had the plan of taking a gap
          year, and applying to physical therapy school for the following year.
          However, the early success of my coaching business helped me in
          deciding to put that off for the time being, and put all of my effort
          into coaching. Fast forward to today, I am a rapidly growing coach,
          with lifters all over the world, including Rhode Island,
          Massachusetts, Connecticut, New York, Virginia, Missouri, California,
          Texas, and even Indonesia.
        </p>
      </div>
    </div>
  );
};

export default About;
