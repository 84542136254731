// src/App.js
import React, { useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Homepage from "./components/Homepage";
import MeetRecaps from "./components/MeetRecaps";
import About from "./components/About";
import FullGallery from "./components/FullGallery";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css"; // Common CSS (if any)

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function HeaderWithRoutes({ servicesRef }) {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/" && <Header servicesRef={servicesRef} />}
      {location.pathname === "/meet-recaps" && (
        <div className="header-image-container">
          <div className="header-overlay"></div>
          <img
            src={require("./assets/images/recaps-header.jpg")}
            alt="Header"
            className="header-image"
          />
        </div>
      )}
      {location.pathname === "/about" && (
        <div className="header-image-container">
          <div className="header-overlay"></div>
          <img
            src={require("./assets/images/about-header.jpg")}
            alt="Header"
            className="header-image"
          />
        </div>
      )}
      {location.pathname === "/contact" && (
        <div className="header-image-container">
          <div className="header-overlay"></div>
          <img
            src={require("./assets/images/contact-header.jpg")}
            alt="Header"
            className="header-image"
          />
        </div>
      )}
    </>
  );
}

function App() {
  const servicesRef = useRef(null);
  const contactRef = useRef(null);
  const headerRef = useRef(null);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <div className="background-overlay" />
        <Navbar servicesRef={servicesRef} contactRef={contactRef} />
        <HeaderWithRoutes servicesRef={servicesRef} />
        <Routes>
          <Route
            path="/"
            element={
              <Homepage servicesRef={servicesRef} contactRef={contactRef} />
            }
          />
          <Route
            path="/meet-recaps"
            element={<MeetRecaps headerRef={headerRef} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/full-gallery" element={<FullGallery />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
